.corpinfo {
    float: left;
    font-size: 14px !important;
    color: #404e58e0 !important;
    font-weight: 600 !important;
}

.roleinfo {
    font-style: italic;
    font-size: 12px;
}

.form-control:read-only {
    background-color: transparent;
}