.rate-table {
  width:100%;
  border: 1px solid black;
  text-align: center; 
  table-layout: fixed;
}

.rate-table>tr>th {
  border: 1px solid black;
  background-color: #1f70db;
  color: white;
  padding: 5px; 
}

.rate-table>tr>td {
  border: 1px solid black;
  padding: 5px;
}

.testimonial_client-name {
  font-weight: bold !important;
  color: #333;
  font-size: 14px !important;
}